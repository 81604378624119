class Tooltip {
  constructor() {
    this.showMobile = this.showMobile.bind(this);
    this.closeMobile = this.closeMobile.bind(this);
    this.showLarge = this.showLarge.bind(this);
    this.closeLarge = this.closeLarge.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.closerSelector = '.pc-Tooltip-closer';
    this.tooltipSelector = '.pc-Tooltip';
    this.triggerSelector =
      'span[data-tooltip], a[data-tooltip], abbr[data-tooltip]';
    $(document).on('click.tooltip', this.triggerSelector, this.showMobile);
    $(document).on('click.tooltip', this.closerSelector, this.closeMobile);
    $(document).on('mouseenter.tooltip', this.triggerSelector, this.showLarge);
    $(document).on('mouseleave.tooltip', this.triggerSelector, this.closeLarge);
    enquire.register(StyleBitz.Layouts.sm, this.closeAll);
    enquire.register(StyleBitz.Breakpoints.md, this.closeAll);
  }

  isMobile() {
    return $(window).width() < 700;
  }

  // Private

  showMobile(e) {
    let left, top, styleId;
    e.preventDefault();
    e.stopPropagation();
    if (!this.isMobile()) {
      return;
    }
    const tgt = this.toTarget(e);
    const curTgt = $(e.currentTarget);
    if (curTgt.parent().hasClass('pc-Subcategories-section-product-card-pricing-details')) {
      top = curTgt.position().top + curTgt.outerHeight() - curTgt.closest('.pc-Subcategories-section-product-card').position().top;
      top = top - Math.floor(tgt.height() / 2);
      left = Math.floor(($('.pc-Subcategories-section-body.products').width() - tgt.width()) / 2) - 16;
      styleId = curTgt.parent().parent().parent().data('style-id');
      tgt.data('style-id', styleId);
      tgt.data('source-event', 'click');
      tgt.css({top, left});
    }
    tgt.toggleClass('is-active');
    return this.emitShowEvent(tgt, 'click', styleId);
  }

  closeMobile(e) {
    if (!this.isMobile()) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    return $(e.target).parents(this.tooltipSelector).removeClass('is-active');
  }

  showLarge(e) {
    let left, top, styleId;
    if (this.isMobile()) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const tgt = this.toTarget(e);
    tgt.addClass('is-active');
    const curTgt = $(e.currentTarget);
    if (curTgt.hasClass('pc-ProductCard-detailPriceInfo')) {
      const containerTopOffset = $('.pc-Styles').offset().top;
      top = curTgt.offset().top - (tgt.outerHeight() + 32) - containerTopOffset;
      left = curTgt.offset().left + curTgt.width() / 2 - 70;
    } else if (curTgt.hasClass('pc-Style-hero-pricing-infoIcon')) {
      top = curTgt.position().top + 2 * curTgt.outerHeight() + 5;
      left = curTgt.position().left + curTgt.outerWidth() - 3;
    } else if (curTgt.parent().hasClass('pc-Subcategories-section-product-card-pricing-details')) {
      top = curTgt.position().top + curTgt.outerHeight() + 12 - curTgt.closest('.pc-Subcategories-section-product-card').position().top;
      left = curTgt.position().left - $('.pc-Subcategories-section-body.products').position().left - 82;
      styleId = curTgt.parent().parent().parent().data('style-id');
      tgt.data('style-id', styleId);
      tgt.data('source-event', 'hover');
    }
    tgt.css({top, left});
    return this.emitShowEvent(tgt, 'hover', styleId);
  }

  closeLarge(e) {
    if (this.isMobile()) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    const tgt = this.toTarget(e);
    return tgt.removeClass('is-active');
  }

  toTarget(e) {
    const el = $(e.target);
    return $(el.data('tooltip') || el.attr('href') || el);
  }

  emitShowEvent(tgt, eventType = 'unknown', styleId = null) {
    const eventData = { target: tgt, type: eventType, styleid: styleId };
    return document.dispatchEvent(new CustomEvent('showtooltip', {detail: eventData}));
  }

  closeAll() {
    return $('.pc-Tooltip.is-active').removeClass('is-active');
  }
}

jQuery(() => (ProductCatalog.Tooltip = new Tooltip()));
