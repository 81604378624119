import {setQueryParam} from 'src/utils/url';
class ShortcutToDesign {
  constructor() {
    this.setViewMoreColorCount = this.setViewMoreColorCount.bind(this);
    this.updateColorImageAndCta = this.updateColorImageAndCta.bind(this);
    this.updateCtas = this.updateCtas.bind(this);
    this.fireCtaEventTags = this.fireCtaEventTags.bind(this);
    this.fireEventTag = this.fireEventTag.bind(this);
    this.labCtaSelector = '.pc-FeaturedProduct-labLink';
    this.detailsCtaSelector = '.pc-FeaturedProduct-detailsLink';
    this.initBehavior();
  }

  // Private

  initBehavior() {
    $(document).on('click', this.labCtaSelector, this.fireCtaEventTags);
    $(document).on('click', this.detailsCtaSelector, this.fireCtaEventTags);
    $(document).on(
      'click',
      '.pc-FeaturedProduct-swatch .pc-Swatch',
      this.updateColorImageAndCta
    );
    enquire.register(StyleBitz.Layouts.sm, () =>
      this.setViewMoreColorCount('sm')
    );
    enquire.register(StyleBitz.Layouts.md, () =>
      this.setViewMoreColorCount('md')
    );
    enquire.register(StyleBitz.Layouts.lg, () =>
      this.setViewMoreColorCount('lg')
    );
    return enquire.register(StyleBitz.Layouts.xl, () =>
      this.setViewMoreColorCount('xl')
    );
  }

  setViewMoreColorCount(size) {
    let visibleColorCount;
    const viewMoreColorsLink = $('.pc-FeaturedProduct-moreColors-detailsLink');
    if (!viewMoreColorsLink.length) {
      return;
    }
    const totalColorCount = parseInt(viewMoreColorsLink.data('total-count'));
    let linkText = viewMoreColorsLink.text();
    if (size === 'sm' || size === 'md') {
      visibleColorCount = parseInt(viewMoreColorsLink.data('sm-count'));
      linkText = `+${totalColorCount - visibleColorCount}`;
    } else {
      visibleColorCount = parseInt(viewMoreColorsLink.data('lg-count'));
      linkText = `+${totalColorCount - visibleColorCount}`;
    }
    viewMoreColorsLink.text(linkText);
  }

  updateColorImageAndCta(e) {
    const swatch = $(e.currentTarget);
    $('.pc-Swatch.is-selected').removeClass('is-selected');
    swatch.addClass('is-selected');
    const image = $('.pc-FeaturedProduct-selectedColor-image');
    image.attr('src', swatch.data('src'));
    image.attr('srcset', swatch.data('srcset'));
    this.updateCtas(swatch.data('colorid'));
  }

  updateCtas(colorid) {
    [this.labCtaSelector, this.detailsCtaSelector].forEach((ctaSelector) => {
      let colorIdParam = colorid;
      if (ctaSelector === this.detailsCtaSelector) {
        colorIdParam = parseInt(colorid);
      }
      ctaSelector = $(ctaSelector);
      const url = setQueryParam('PK', colorIdParam, ctaSelector.attr('href'));
      return ctaSelector.attr('href', url);
    });
  }

  fireCtaEventTags(e) {
    const el = $(e.currentTarget);
    let action = 'start designing';
    if (
      el.hasClass('pc-FeaturedProduct-detailExtra-detailsLink') ||
      el.hasClass('pc-FeaturedProduct-detailActions-detailsLink')
    ) {
      action = 'product details';
    } else if (el.hasClass('pc-FeaturedProduct-moreColors-detailsLink')) {
      action = 'color palette';
    } else if (el.hasClass('pc-FeaturedProduct-detailTitle-detailsLink')) {
      action = 'product name';
    }
    return this.fireEventTag(action, null);
  }

  fireEventTag(action, label) {
    return CustomInk.Metrics.fireEvent({
      category: 'shortcut to design',
      action,
      label,
      interaction: false
    });
  }
}

jQuery(() => (ProductCatalog.ShortcutToDesign = new ShortcutToDesign()));
