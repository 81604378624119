const baseUrl = (url = window.location.href) => {
  const urlObj = new URL(url, location);
  return urlObj.origin + urlObj.pathname;
};

const searchParams = (url = window.location.href) => {
  return new URL(url, location).searchParams;
};

const getQueryParam = (name, url = window.location.href) => {
  return searchParams(url).get(name);
};

const getQueryString = (url = window.location.href) => {
  return new URL(url, location).search;
};

const setQueryParam = (name, value, url = window.location.href) => {
  const params = searchParams(url);
  params.set(name, value);
  return `${baseUrl(url)}?${params.toString()}`;
};

const deleteQueryParam = (name, url = window.location.href) => {
  const params = searchParams(url);
  params.delete(name);
  const queryString = params.toString();
  return queryString === '' ? baseUrl(url) : `${baseUrl(url)}?${queryString}`;
};

const isCatalogPath = (urlStr) => {
  const catalogPathPattern = /^\/products(?:\/[\w-]+){1,2}\/\d+(?:\?.*)?$/;
  const pathname = urlStr.startsWith('http')
    ? new URL(urlStr).pathname
    : urlStr;
  return catalogPathPattern.test(pathname);
};

const isIframer = () => {
  return Boolean(getQueryParam('iframer'));
};

export {
  baseUrl,
  searchParams,
  getQueryParam,
  getQueryString,
  setQueryParam,
  deleteQueryParam,
  isCatalogPath,
  isIframer
};
