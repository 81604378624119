class Valueadds {
  constructor() {
    this.registerWaypoint();
  }

  // Private

  registerWaypoint() {
    this.valueAddsWaypoint = $('.pc-ValueAdds-list-item').waypoint({
      handler: this.waypointHandlerValueAdd,
      offset: '75%'
    });
  }

  waypointHandlerValueAdd(direction) {
    if (direction === 'down') {
      $(this.element).addClass('checked');
    }
  }
}

jQuery(() => (ProductCatalog.Valueadds = new Valueadds()));
