import 'src/subcategories/shortcut_to_design';
import 'src/subcategories/value_adds';
import 'src/shared/tooltip';
import 'src/tests/subcat_redesign_test';

const ecommerce = {
  item_list_id: parseInt($('meta[property=categoryinfo]').prop('id')),
  item_list_name: $('meta[property=categoryinfo]').attr('category_tree')
};
jQuery(() => ProductCatalog.gtmEcomEvent('products_subcategories', ecommerce));
