import Cookies from 'js-cookie';
export function featureFlag(feature) {
  const cookieValue = Signalman.featureFlagFromCookie(feature);
  if (typeof cookieValue !== 'undefined' && cookieValue !== null) {
    return cookieValue;
  } else {
    return Signalman.getFeatureFlagFromServer(feature);
  }
}

export function featureFlagFromCookie(feature) {
  return Cookies.get(`feature_${feature}`);
}

export function getFeatureFlagFromServer(feature) {
  let returnVal = null;
  $.ajax({
    url: `/products/signalman/features/${feature}.json`,
    dataType: 'json',
    async: false
  }).done((data) => {
    returnVal = data[0];
    Signalman.setFeatureFlagCookie(feature, returnVal);
  });
  return returnVal;
}

export function setFeatureFlagCookie(feature, value) {
  Cookies.set(`feature_${feature}`, value, Signalman.cookieOptions());
}

export function cookieOptions() {
  let domain;
  const hostArray = location.hostname.split('.');
  const defaultExpirationDays = 365;
  if (hostArray.includes('localhost')) {
    return {path: '/', expires: defaultExpirationDays, domain: null};
  } else if (hostArray[hostArray.length - 1].match(/\d/)) {
    return {
      path: '/',
      expires: defaultExpirationDays,
      domain: location.hostname
    };
  } else {
    domain = `.${
      hostArray.length > 1
        ? `${hostArray[hostArray.length - 2]}.${
            hostArray[hostArray.length - 1]
          }`
        : hostArray[0]
    }`;
    return {path: '/', expires: defaultExpirationDays, domain};
  }
}
